<script setup lang="ts">
  import { MpBox } from '@borg/ui';

  defineProps<{
    exclusive?: boolean;
  }>();

  const jobCard = useJobCard.inject();

  const badges = computed(() => {
    if (
      jobCard.organization &&
      jobCard.organization !== 'incognito' &&
      jobCard.organization.badges?.length
    ) {
      return jobCard.organization.badges;
    }
    return [];
  });
</script>

<template>
  <MpBox
    v-if="badges.length"
    :surface="exclusive ? '30' : '20'"
    :class="{
      'badges-container': true,
      'badges-container--exclusive': exclusive,
    }"
  >
    <img
      v-for="(badge, i) in badges"
      :key="i"
      class="badges-container__image"
      loading="lazy"
      :src="badge.image"
      alt="badge"
    />
  </MpBox>
</template>

<style scoped lang="scss">
  .badges-container {
    --parent-padding: var(--mp-space-60);

    padding: var(--mp-space-50) var(--parent-padding);
    display: flex;
    flex-wrap: wrap;
    gap: var(--mp-space-50) var(--mp-space-60);
    justify-content: center;
    width: calc(100% + 2 * var(--parent-padding));
    position: relative;
    left: calc(-1 * var(--parent-padding));
    margin-bottom: calc(-1 * var(--parent-padding));
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid var(--mp-color-grey-200);

    @include dark-mode {
      border-color: var(--mp-color-grey-800);
    }

    &--exclusive {
      --parent-padding: var(--mp-space-70);

      margin-top: var(--mp-space-50);
      border-top: none;
    }

    &__image {
      object-fit: contain;
      width: fit-content;
      height: fit-content;
      max-width: 3.2rem;
      max-height: 3.2rem;
    }
  }
</style>
