<script setup lang="ts">
  import { MpCard, MpDivider } from '@borg/ui';
  import { JobDetailsSource, JobGroup, Unpacked } from '@borg/types';

  const props = defineProps<{
    jobGroup: JobGroup;
    source?: JobDetailsSource;
  }>();

  defineSlots<{
    postContent: (props: { job: Unpacked<JobGroup['jobs']> }) => void;
  }>();

  const jobCard = useJobCard.provide();
  jobCard.set(props.jobGroup, props.source);
  const organizationCard = useOrganizationCard.provide();
  organizationCard.set(props.jobGroup.organization);
</script>

<template>
  <MpCard
    class="job-card"
    shadow
    border="light-only"
  >
    <OrganizationLogo />
    <template
      v-for="(job, i) in jobGroup.jobs"
      :key="job.id"
    >
      <div>
        <JobCardContent
          :job="job"
          :organization="jobGroup.organization"
          :source="source"
        />
        <slot
          name="postContent"
          :job="job"
        />
      </div>
      <MpDivider
        v-if="i + 1 !== jobGroup.jobs.length"
        margin="none"
      />
    </template>
    <JobCardBadges />
  </MpCard>
</template>

<style scoped lang="scss">
  .job-card {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-60);
    max-width: 100%;

    & > :first-child {
      margin-bottom: var(--mp-space-30);
    }
  }
</style>
